<template>
  <v-card>
    <v-data-table
      :headers="headers"
      :items="lista_3_array"
      item-key="name"
      class="elevation-1"
      :search="search"
      :loading="loading"
    >
      <template v-slot:[`item.resultado`]="{ item }" >
        <v-chip
          class="white--text"
          label
          outlined
          :color="item.resultado.cor"
        >
          {{item.resultado.text}}
        </v-chip>
      </template>
      <template v-slot:top>
        <v-row class="ma-auto">
          <v-col>
            filtro
          </v-col>
          <v-col>
            <v-text-field
              v-model="search"
              label="Pesquisar"
              class="mx-0"
            ></v-text-field>
          </v-col>
          <v-col cols="1" class="text-right">
            <v-btn 
                outlined 
                icon
                @click="listar"
            >
              <v-icon>mdi-update</v-icon>
            </v-btn>
        </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.actions`]="{ item }">        
        <Sugestao :current-item="item"/>
      </template>     
      <template 
        v-slot:[`item.edit`]="{ item }">
        <Editar v-if="processa(item.resultado.id)" :current-item="item" :is-ava="avaliador" :is-adm="administrador" />
      </template>     
    </v-data-table>
  </v-card>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name:'Avaliador',
  data:()=>({
    search: '',
    avaliador: false,
    administrador: false,
    headers: [
      { text: 'Nª reg.',align: 'start',sortable: true, value: 'id',},
      { text: 'Colaborador',align: 'start',sortable: true, value: 'usuario.text',},
      { text: 'Avaliador',align: 'start',sortable: true, value: 'avaliador.text',},
      { text: 'Setor',align: 'start',sortable: true, value: 'local.name',},
      { text: 'Título',align: 'start',sortable: true, value: 'titulo',},
      { text: 'Andamento da sua sugestão',align: 'start',sortable: false, value: 'resultado',},
      { text: 'Ver',align: 'center',sortable: false, value: 'actions'},
      { text: 'Editar',align: 'center',sortable: false, value: 'edit'},
    ]
  }),
  components:{
    Sugestao:()=> import('./Sugestao'),
    Editar:()=> import('./EditAvaliador')
  },
  computed:{
    ...mapState(['loading','lista_3_array']),
    ...mapGetters(['permissoes']),
  },
  methods:{
    processa(val=0){
      val
      let cond = JSON.parse(localStorage.getItem('cfgu'))
      cond
      return 1 // cond.conf.status_freeze.find(e=>e==val)==undefined||false
    }
    ,listar(){
      this.$store.dispatch('lista',{
        method: 'get',
        url: 'informe/sugestoes/?papel=2',
        instancia:'lista_3_array'
      })
      this.$store.dispatch('btnLoading',false)
    },
  },
  mounted(){
    this.listar()

    if (this.permissoes.papel_default === 2){
      this.avaliador = true
    }
    else if (this.permissoes.papel_default === 3){
      this.administrador = true
    }
    
  }
}
</script>